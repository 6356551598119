var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"resourceSaveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{ref:"addMultipleChoiceAnswerModalRef",attrs:{"id":"addMultipleChoiceAnswerModal","body-class":"position-static","centered":"","size":"xl","title":"Thêm đáp án cho câu hỏi trắc nghiệm","no-close-on-backdrop":true},on:{"hide":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"disabled":invalid || _vm.createChoiceAnswersReqDtos.length === 0,"variant":"primary"},on:{"click":_vm.onSave}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Lưu lại ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('addMultipleChoiceAnswerModal')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v(" Hủy ")],1)])],1)]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form',{ref:"formRef",style:({height: _vm.trHeight})},_vm._l((_vm.createChoiceAnswersReqDtos),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Tên đáp án "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên đáp án","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Nhập tên đáp án","state":_vm.getElementState(errors)},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label-for":"content"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nội dung "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Nội dung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"content","name":"content","placeholder":"Nội dung","state":_vm.getElementState(errors)},model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label-for":"orderNo"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Thứ tự đáp án "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Thứ tự đáp án","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"orderNo","name":"orderNo","placeholder":"Thứ tự đáp án","state":_vm.getElementState(errors)},model:{value:(item.orderNo),callback:function ($$v) {_vm.$set(item, "orderNo", $$v)},expression:"item.orderNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label-for":"score"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Điểm cho đáp án "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Điểm cho đáp án","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"score","name":"score","type":"number","placeholder":"Điểm cho đáp án","state":_vm.getElementState(errors)},model:{value:(item.score),callback:function ($$v) {_vm.$set(item, "score", $$v)},expression:"item.score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label-for":"score"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"value":"1","unchecked-value":"0"},model:{value:(item.multiple),callback:function ($$v) {_vm.$set(item, "multiple", $$v)},expression:"item.multiple"}},[_vm._v(" Câu hỏi có nhiều đáp án ")]),_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"value":"1","unchecked-value":"0"},model:{value:(item.isCorrect),callback:function ($$v) {_vm.$set(item, "isCorrect", $$v)},expression:"item.isCorrect"}},[_vm._v(" Là đáp án đúng ")])],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Xóa")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"success"},on:{"click":_vm.repeatAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Thêm mới")])],1)],1)],1),_c('b-overlay',{attrs:{"variant":"white","spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm","no-wrap":"","show":_vm.isLoading}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }